<template>
  <div class="rightSide">
    <div class="rightSide_inner">
      <div class="rightSide_inner_title">
        <p style="font-size: 30px; color: #333333;padding-left: 10px">
          {{ $t("Display Sample Images") }}
        </p>
      </div>
      <div class="content">
        <div class="images-list" v-if="categoryData.length > 0">
          <div class="images" v-for="(item, index) in categoryData" :key="index" @click="handleClick(item)">
            <el-image :src="item.categoryImg" fit="fill" style="width: 300px; height: 400px">
              <div slot="placeholder" class="image-slot">
                loading<span class="dot">...</span>
              </div>
            </el-image>
            <div class="images-title">{{ item.categoryName }}</div>
          </div>
        </div>
        <div class="footer">
          <div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pageData.page" :page-size="pageData.pagesize" layout="total,  prev, pager, next, jumper"
              :total="pageData.total" background>
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      categoryData: [],
      pageData: {
        page: 1,
        pagesize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.handleGetCategory()
  },
  methods: {
    handleClick(item) {
      sessionStorage.setItem("categoryType", item.categoryType)
      this.$router.push({ path: '/web/orderCenter/catagory/images', query: { "categoryid": item.id } })
    },
    handleGetCategory() {
      let params={
        page: this.pageData.page,
        pagesize: this.pageData.pagesize,
      };
      this.$axios.get('/w1/display/category', { params: params }).then(res => {
        const { data } = res.data
        this.categoryData = data.data
        this.pageData.total = data.total
      })
    },
    handleSizeChange(val) {
      this.pageData.pagesize = val;
      this.handleGetCategory();
    },
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.handleGetCategory();
    },
  },
};

</script>

<style scoped lang="scss">
::v-deep {
  .footer .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #218da0; //修改后的背景图颜色
    color: #fff;
  }
}

.footer {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightSide {
  width: 1320px;
  margin: 15px 0 0 15px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  &_inner {
    width: 100%;
    height: 100%;

    &_title {
      height: 68px;
      display: flex;
      align-items: center;
    }
  }
}

.images-list {
  // min-height: 800px;
  display: inline flow-root list-item;
  list-style: none;

  .images {
    width: 320px;
    height: 460px;
    float: left;
    margin: 10px 5px;
  }

  .images-title {
    text-align: center;
    padding: 10px;
  }
}
</style>
